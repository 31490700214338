import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import { Select, Space } from "antd";
import { Link } from "react-router-dom";
import agreement from "../Agreement/agreement.pdf"
import useScroll from "./useScroll";

function Navbar() {
  let counterNavbar = 1;
  const scroll = useScroll();

  useEffect (() => {
    if(scroll.y > 0) {
      document.querySelector('#nav').style = "background-color: white;"
    } else {
      document.querySelector('#nav').style = "background: linear-gradient(to bottom , rgba(255, 255, 255, 0.779) , rgba(255, 255, 255, 0));"
    }
  })
  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };

  // const [navClassList, setNavClassList] = useState([]);
  // const scroll = useScroll();

  // // update classList of nav on scroll
  // useEffect(() => {
  //   const _classList = [];

  //   if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
  //     _classList.push("nav-bar--hidden");
  //   setNavClassList(_classList);
  // }, [scroll.y, scroll.lastY]);

  return (
    <nav id="nav">
      <div className="container">
        <div className="left-panel">
          <a href="/"><img id="logo" src={logo} alt="404"/></a>
        </div>
        <div className="right-panel">
          <div className="buttons-nav">
            <a href="tel:+998912469699">+998 91 647-59-59</a>
            <Link id="button" to='/about'>Biz haqimizda</Link>
          </div>
        </div>
        <div className="nav-menu">
          {/* <Space wrap>
            <Select
              id="select"
              defaultValue="uz"
              style={{
                width: 70,
                height: 40,
                border: "1px solid black",
                borderRadius: 7,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "uz",
                  label: `UZ`,
                },
                {
                  value: "en",
                  label: "EN",
                },
                {
                  value: "ru",
                  label: "RU",
                },
              ]}
            />
          </Space> */}
          <i
            className="bx bx-menu"
            onClick={() => {
              if (counterNavbar === 1) {
                document.querySelector(
                  ".responsiv-navbar"
                ).style = `height: 30vh;`;
                document.querySelector(".texts").style = `display: block;`;
                counterNavbar--;
              } else if (counterNavbar === 0) {
                document.querySelector(
                  ".responsiv-navbar"
                ).style = `height: 0;`;
                setTimeout(() => {
                  document.querySelector(".texts").style = `display: none;`;
                }, 200);
                counterNavbar = counterNavbar + 1;
              }
            }}
          ></i>
        </div>
      </div>
      <div className="responsiv-navbar">
        <div className="container">
          <div className="texts">
            <div>
              <a href={"https://www.google.com/maps/place/40%C2%B004'28.6%22N+64%C2%B034'23.7%22E/@40.074649,64.572559,14z/data=!4m4!3m3!8m2!3d40.07461!4d64.57324?hl=en&entry=ttu"} target={"_blank"}>Bizning manzil</a>
            </div>
            <div>
              <a href={agreement} download="agreement">Shartnoma bilan tanishish</a>
            </div>
            <div>
              <a href="">Biz bilan bog'lanish</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
