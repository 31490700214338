import React from "react";

function TimeTable() {
  return (
    <div className="time-table">
      <div className="container">
        <div className="texts">
          <h1>O'quvchilarning kun tartibi</h1>
        </div>
        <div className="lessons-time-table">
          <table className="table table-striped table-bordered text-center">
            <thead className="table-dark">
              <tr>
                <th scope="col">Time</th>
                <th scope="col">8:30-9:30</th>
                <th scope="col">9:30-10:30</th>
                <th scope="col">10:3-11:30</th>
                <th scope="col">11:30-12:30</th>
                <th scope="col">12:30-1:30</th>
                <th scope="col">1:30-2:00</th>
                <th scope="col">3:00-3:30</th>
                <th scope="col">3:00-4:00</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Dushanba</th>
                <td>Ingliz tili</td>
                <td>Fizika</td>
                <td>Matematika</td>
                <td>Ona tili</td>
                <td rowspan="5" id="launch">
                  T<br/>U<br/>S<br/>H<br/>L<br/>I<br/>K
                </td>
                <td>Informatika</td>
                <td>Jismoniy Tarbiya</td>
                <td>Biologiya</td>
              </tr>
              <tr>
                <th scope="row">Seshanba</th>
                <td>Ingliz tili</td>
                <td>Fizika</td>
                <td>Matematika</td>
                <td>Ona tili</td>
                <td>Informatika</td>
                <td>Jismoniy Tarbiya</td>
                <td>Biologiya</td>
              </tr>
              <tr>
                <th scope="row">Chorshanba</th>
                <td>Ingliz tili</td>
                <td>Fizika</td>
                <td>Matematika</td>
                <td>Ona tili</td>
                <td>Informatika</td>
                <td>Jismoniy Tarbiya</td>
                <td>Biologiya</td>
              </tr>
              <tr>
                <th scope="row">Payshanba</th>
                <td>Ingliz tili</td>
                <td>Fizika</td>
                <td>Matematika</td>
                <td>Ona tili</td>
                <td>Informatika</td>
                <td>Jismoniy Tarbiya</td>
                <td>Biologiya</td>
              </tr>
              <tr>
                <th scope="row">Juma</th>
                <td>Ingliz tili</td>
                <td>Fizika</td>
                <td>Matematika</td>
                <td>Ona tili</td>
                <td>Informatika</td>
                <td>Jismoniy Tarbiya</td>
                <td>Biologiya</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TimeTable;
