import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Main from "./pages/Main";
import "./style/style.scss";
import Coursess from "./pages/Coursess";
import Footer from "./components/Footer";
import call from "./images/call.png";
import About from "./pages/About";
import Education from "./pages/Education";
import School from "./pages/School";
import ExactSciences from "./pages/ExactSciences";
import SocialSciences from "./pages/SocialSciences";
import NaturalSciences from "./pages/NaturalSciences";

import Mathematical from "./pages/Sciences/Mathematical";

import Teachers from "./pages/Teachers";
import Results from "./pages/Results";
import TimeTable from "./pages/TimeTable";
import MaOn from "./pages/Sciences/MaOn";
import MaIn from "./pages/Sciences/MaIn";
import MaFi from "./pages/Sciences/MaFi";
import MaGe from "./pages/Sciences/MaGe";
import InOn from "./pages/Sciences/InOn";
import TaOn from "./pages/Sciences/TaOn";
import TaIn from "./pages/Sciences/TaIn";
import HuIn from "./pages/Sciences/HuIn";
import RuOn from "./pages/Sciences/RuOn";
import TaGe from "./pages/Sciences/TaGe";
import KiBi from "./pages/Sciences/KiBi";
import BiOn from "./pages/Sciences/BiOn";
import BiFi from "./pages/Sciences/BiFi";
import KiIn from "./pages/Sciences/KiIn";

function App() {
  return (
    <BrowserRouter>
      <a href="tel:+998978002809">
        <img id="call" src={call} alt="404" />
      </a>
      <Navbar />
      <Routes>
        <Route index element={<Main />}></Route>
        <Route path="courses" element={<Coursess />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="education" element={<Education />}></Route>
        <Route path="school" element={<School />}></Route>
        <Route path="exactSciences" element={<ExactSciences />}></Route>
        <Route path="socialSciences" element={<SocialSciences />}></Route>
        <Route path="naturalSciences" element={<NaturalSciences />}></Route>
        
        <Route path="mathematical" element={<Mathematical />}></Route>
        
        <Route path="teachers" element={<Teachers />}></Route>
        <Route path="results" element={<Results />}></Route>
        <Route path="time-table" element={<TimeTable />}></Route>
        <Route path="maon" element={<MaOn />}></Route>
        <Route path="main" element={<MaIn />}></Route>
        <Route path="mafi" element={<MaFi />}></Route>
        <Route path="mage" element={<MaGe />}></Route>
        <Route path="inon" element={<InOn />}></Route>
        <Route path="taon" element={<TaOn />}></Route>
        <Route path="tain" element={<TaIn />}></Route>
        <Route path="huin" element={<HuIn />}></Route>
        <Route path="ruon" element={<RuOn />}></Route>
        <Route path="tage" element={<TaGe />}></Route>
        <Route path="kibi" element={<KiBi />}></Route>
        <Route path="bion" element={<BiOn />}></Route>
        <Route path="bifi" element={<BiFi />}></Route>
        <Route path="kiin" element={<KiIn />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
