import React from "react";
import { Link } from "react-router-dom";
import student from "../images/student.png";

function ExactSciences() {
  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);
  return (
    <div className="courses-main">
      <div className="container">
        <div className="top-part">
          <div className="course-main">
            <h1>O'zingizga mos yo'nalishni tanlang </h1>
            <p>
              Biz bilan birga dunyodagi eng rivojlangan va talab yuqori bo'lgan
              sohalar mutaxasisi bo'ling. Buning uchun bizning kurslarimiz siz
              uchun juda ham ajoyib taklif
            </p>
          </div>
        </div>
        <div className="bottom-part">
          <div className="course-card">
            <Link to={"/mafi"}>
              <div className="card" id="card-1">
                <div className="first-text">
                  <h1>Matematika-Fizika</h1>
                </div>
                <div className="second-text">
                  <p>
                    Transport muhandisligi , aviatsiya va temir yo'l
                    muhandisligi , O'qituchilik , Injineringq , Dasturchilik va
                    IT sohalari mutaxasislari uchun
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/main"}>
              <div className="card" id="card-2">
                <div className="first-text">
                  <h1>Matematika-Chet tili</h1>
                </div>
                <div className="second-text">
                  <p>
                    Transport muhandisligi , aviatsiya va temir yo'l
                    muhandisligi , O'qituchilik , Injinering , Dasturchilik va
                    IT sohalari mutaxasislari uchun
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/mage"}>
              <div className="card" id="card-3">
                <div className="first-text">
                  <h1>Matematika-Geografiya</h1>
                </div>
                <div className="second-text">
                  <p>
                    Transport muhandisligi , aviatsiya va temir yo'l
                    muhandisligi , O'qituchilik , Injinering , Dasturchilik va
                    IT sohalari mutaxasislari uchun
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/maon"}>
              <div className="card" id="card-4">
                <div className="first-text">
                  <h1>Matematika-Ona tili</h1>
                </div>
                <div className="second-text">
                  <p>
                    Transport muhandisligi , aviatsiya va temir yo'l
                    muhandisligi , O'qituchilik , Injinering , Dasturchilik va
                    IT sohalari mutaxasislari uchun
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="benefits">
          <div className="container">
            <div className="left-div">
              <div className="text">
                <h1>Imtiyozlar</h1>
                <p>
                  Agarda sizning hozirgi moliyaviy vaziyatingiz bizda o'qish
                  imkoniyatini bera olmasa afsuslanmang ! Bizning maxsus
                  grantimizni yutib olish imkoniyatingiz mavjud !
                </p>
                <button>
                  Batafsil <i className="bx bx-chevron-right"></i>
                </button>
              </div>
            </div>
            <div className="right-div">
              <img src={student} alt="404" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExactSciences;
