import React, { useRef, useState } from "react";
import img from "../images/Teachers/Matematika.png";
import img2 from "../images/Teachers/Matematika_2.png";
import img3 from "../images/Teachers/Shoxruxxon.jpg";
import img4 from "../images/Teachers/Ingliz_tili.jpg";
import img5 from "../images/Teachers/Ingliz_tili_2.jpg";
import img6 from "../images/Teachers/Ingliz_tili_3.jpg";
import img7 from "../images/Teachers/Ingliz_tili_4.jpg";
import img8 from "../images/Teachers/Geografiya.jpg";
import img9 from "../images/Teachers/Ona_tili.png";
import img10 from "../images/Teachers/Biologiya.png";
import img11 from "../images/Teachers/Fransuz_tili.jpg";
import img12 from "../images/Teachers/Kimyo.png";
import img13 from "../images/Teachers/Rus_tili.jpg";
import img14 from "../images/Teachers/Tarix.jpg";
import educator1 from "../images/Teachers/educator-1.png";
import educator2 from "../images/Teachers/educator-2.png";
import teachers from "../images/teachers.webp";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";
function Teachers() {
  const [isPlaying, setIsPlayingg] = useState(false);
  const videoRef = useRef(null);

  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);

  return (
    <div className="teachers-page">
      <div className="container">
        <h1>
          O'qituvchilar <span>Jamoasi</span>
        </h1>
        <div className="slider">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 1,
              stretch: 5,
              depth: 400,
              modifier: 3,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={img} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img3} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img4} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img5} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img6} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img7} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img8} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img9} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img10} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img11} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img12} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img13} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img14} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={educator1} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={educator2} />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="icons">
          <img src={teachers} alt="404" />
          <div className="texts">
            <h1>
              Sizni <br /> <span id="first">Sulaymonov</span>{" "}
              <span id="second">Academy</span> jamoasida kutib qolamiz <br />
              <button>
                Bog'lanish <i class="bx bx-right-arrow-alt"></i>
              </button>
              <button id="location">
                Manzil <i class="bx bx-right-arrow-alt"></i>
              </button>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teachers;
