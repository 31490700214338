import React from "react";
import about1 from "../images/about_1.jpg";
import forebidden from "../images/forebidden.png";
import prize from "../images/prize.png";
import group from "../images/Teachers/Group.jpg";
import logo from "../images/logo-first.png";

function About() {

  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);

  return (
    <div className="about-main">
      <div className="container">
        <div className="second-information">
          <div className="left-div">
            <div className="text">
              <p>
                <span id="tartib">Tartib</span> va{" "}
                <span id="intizom">Intizom</span>
              </p>
              <h1>
                Farzandingiz doimo bizning nazoratimiz ostida bo'ladi.Har bir
                xonada kamera o'rnatilgan va qatiy tartib intizom tizimi
                o'quvchilarning darsdan boshqa ishlarga chalg'imaslikka imkon
                yaratadi.Bizning o'quv markazimizda Sharqona ta'lim tizimi
                asosida darslar olib boriladi.Akademiya hududida o'quvchilarga
                mobil qurilmalar ishlatish mutlaqo taqiqlangan !
              </h1>
              <button>Biz bilan bog'lanish</button>
            </div>
          </div>
          <div className="right-div">
            <img src={forebidden} id="forebidden" alt="404" />
          </div>
        </div>
        <div className="three-information">
          <div className="right-div">
            <div className="text">
              <h1>
                Rag'batlantirish uchun <span id="prize">SOVG'ALAR</span>
              </h1>
              <p>
                Bizda har oyning oxirida blok testlar o'tkiziladi va blok
                testidan yuqori ball olgan o'quvchilar turli xil sovg'alar bilan
                rag'batlantiriladi.Odatda faqatgina blok test jarayonida emas
                balki dars jarayonida ham o'qituvchilarimiz tomonidan
                rag'batlantiriladi
              </p>
            </div>
          </div>
          <div className="left-div">
            <img src={prize} alt="404" />
          </div>
        </div>
        <div className="fourth-information">
          <div className="top-div">
            <img src={group} alt="404" />
          </div>
          <div className="bottom-div">
            <div className="text">
              <h1>Sizni Sulaymonov Academy jamoasida</h1>
              <p>Kutib qolamiz !</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
