import React from "react";
import thumbnail from "../images/sa.png";
import video from "../videos/SulaymonovAcademy.mp4";
import logo from "../images/logo-first.png";

function AboutUsVideo() {
  return (
    <div className="about-us-video">
      <div className="container">
        <h1>Biz haqimizda</h1>
        <div className="panels">
          <div className="left-panel">
            <div className="card">
              <img src={logo} alt="404" />
              <div className="texts">
                <h2>
                  Sulaymonov <span>Academy</span> maktabi
                </h2>
                <p>
                  Sulaymonov Academy xususiy maktabi 2020 yildan beri o'z
                  faoliyatini yuritib kelmoqda va hozirgacha 500 dan ortiq
                  o'quvchilarni o'qitdi.Akademiyada boshqa viloyatdan va
                  poytaxtdan ham o'quvchilar kelib tahsil olishadi
                </p>
              </div>
            </div>
          </div>
          <div className="right-panel">
            <video controls poster={thumbnail} src={video}></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsVideo;
