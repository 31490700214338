import React from "react";
import logo from "../images/logo.png";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="top">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1526.5158757304894!2d64.57173021301219!3d40.0747038447727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f506f00129395c3%3A0x192b8380b85a7f63!2sSulaymonov%20Academy!5e0!3m2!1sru!2s!4v1711131494522!5m2!1sru!2s"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="bottom">
          <div className="container-child">
            <div className="footer-card" id="logo">
              <img src={logo} alt="404" />
            </div>
            <div className="footer-card" id="academy">
              <h1>Akademiya</h1>
              <div className="texts">
                <a>Biz haqimizda</a>
                <a>Yangiliklar</a>
                <a>Kontakt</a>
              </div>
            </div>
            <div className="footer-card" id="courses">
              <h1>Yo'nalishlar</h1>
              <div className="texts">
                <a>Matematika - Fizika</a>
                <a>Matematika - ingliz tili</a>
                <a>Matematika - Fransuz tili</a>
                <a>Tarix - Geografiya</a>
                <a>Tarix - Ona tili</a>
                <a>Kimyo - Biologiya</a>
                <a>Huquq - Ingliz tili</a>
              </div>
            </div>
            <div className="footer-card" id="location">
              <h1>Bog'lanish</h1>
              <div className="texts">
                <a>Manzil: Buxoro vil G'ijduvon tum <br /> Gajdumak MFY</a>
                <a href="https://www.sulaymonov.uz" target="blank">
                  www.sulaymonov.uz
                </a>
                <a href="tel:+998978002809">+998 (91) 647 - 59 - 59</a>
              </div>
            </div>
            <div className="footer-card" id="social">
              <div className="circle">
                <i
                  className="bx bxl-telegram"
                  id="telegram"
                  onClick={() => {
                    window.open(`https://t.me/gajdumak_ziyo`);
                  }}
                >
                  {" "}
                  Telegram
                </i>
              </div>
              <div className="circle">
                <i
                  className="bx bxl-instagram"
                  onClick={() => {
                    window.open(`https://www.instagram.com/sulaymonov_academy`);
                  }}
                >
                  {" "}
                  instagram
                </i>
              </div>
              <div className="circle">
                <i
                  className="bx bxl-facebook"
                  onClick={() => {
                    window.open(`https://t.me/gajdumak_ziyo`);
                  }}
                >
                  {" "}
                  Facebook
                </i>
              </div>
              <div className="circle">
                <i
                  className="bx bxl-youtube"
                  onClick={() => {
                    window.open(`https://www.youtube.com/@sulaymonov_academy`);
                  }}
                >
                  {" "}
                  Youtube
                </i>
              </div>
            </div>
          </div>
          <div className="respons-container-child">
            <div className="container-res">
              <p>O'zbekiston, Buxoro viloyati, G'ijduvon tumani, Gajdumak MFY </p>
              <p>www.sulaymonov.uz</p>
              <p>+998 (91) 647-59-59</p>
              <div className="social-icons">
                <div className="circle" onClick={() => {window.open("https://t.me/gajdumak_ziyo")}}>
                  <i className="bx bxl-telegram"></i>
                </div>
                <div className="circle" onClick={() => {window.open("https://www.instagram.com/sulaymonov_academy/")}}>
                  <i className="bx bxl-instagram"></i>
                </div>
                <div className="circle" onClick={() => {window.open("https://www.youtube.com/@sulaymonov_academy")}}>
                  <i className="bx bxl-youtube"></i>
                </div>
              </div>
              <div className="privacy">
                <p>Privacy Policy</p>
                <p>© Sulaymonov Academy 2022</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
