import React from "react";
import { Link } from "react-router-dom";

function SchoolMain() {
  return (
    <div className="school-main">
      <div className="style-for"></div>
      <div className="container">
        <div className="left-div">
          <div className="texts">
            <h1>
              Yuksak <span>bilim</span> va <span>intizom</span> maskani
            </h1>
            <p>Sifatli ta'lim maktabi</p>
            <div className="buttons">
              <a href="tel:+998912469699">Bepul konsultatsiya</a>
              <a href="https://maps.app.goo.gl/ceSEgwY4LxUcSVw36" target={"_blank"}>Manzil</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolMain;
