import React, { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table } from "antd";
import img from "../../images/stylefor.png";
import operator from "../../images/operator.png";
const data = [
  {
    key: "1",
    univercity: "Andijon davlat universiteti",
    acceptance: 1175,
    grant: 295,
    contract: 880,
  },
  {
    key: "2",
    univercity: "Buxoro davlat universiteti",
    acceptance: 2323,
    grant: 590,
    contract: 1733,
  },
  {
    key: "3",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 2770,
    grant: 655,
    contract: 2115,
  },
  {
    key: "4",
    univercity: "Guliston davlat universiteti",
    acceptance: 2125,
    grant: 590,
    contract: 1535,
  },
  {
    key: "5",
    univercity: "Jamoat xavfsizligi universiteti",
    acceptance: 130,
    grant: 0,
    contract: 130,
  },
  {
    key: "6",
    univercity: "Jizzax politexnika instituti",
    acceptance: 1775,
    grant: 585,
    contract: 1190,
  },
  {
    key: "7",
    univercity: "Buxoro tabiiy resurslarni boshqarish instituti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Guliston davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity:
      "Islom Karimov nomidagi Toshkent davlat texnika universiteti Qoʻqon filiali",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Jamoat xavfsizligi universiteti",
    acceptance: 130,
    grant: 0,
    contract: 130,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
  {
    key: "8",
    univercity: "Farg‘ona davlat universiteti",
    acceptance: 600,
    grant: 90,
    contract: 1450,
  },
];

function TaGe() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Universitetlar ro'yxati",
      dataIndex: "univercity",
      key: "univercity",
      width: "48%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Umumiy Qabul",
      dataIndex: "acceptance",
      key: "acceptance",
      width: "10%",
      sorter: (a, b) => a.grant - b.grant,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Davlat granti asosida",
      dataIndex: "grant",
      key: "grant",
      width: "10%",
      sorter: (a, b) => a.grant - b.grant,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Shartnoma asosida",
      dataIndex: "contract",
      key: "contract",
      width: "10%",
      sorter: (a, b) => a.grant - b.grant,
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <div className="mathematical">
      <div className="container">
        <div className="top"></div>
        <h1>
          <span>Tarix-Geografiya</span> yo'nalishidagi oliy ta'limlariga qabul
          kvotalari
        </h1>
        <Table className="table" columns={columns} dataSource={data} />
        <div className="call-center">
          <div className="card">
            <div className="text-call">
              <div className="div">
                <h1>
                  Qabul haqida batafsil ma'lumot olishingiz uchun biz bilan
                  bog'laning
                </h1>
                <button>Bog'lanish</button>
              </div>
            </div>
            <div className="images">
              <img id="img1" src={img} alt="404" />
              <img id="img2" src={img} alt="404" />
            </div>
            <img id="operator" src={operator} alt="404" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaGe;
