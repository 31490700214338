import React from "react";
import { Link } from "react-router-dom";
import background from "../images/background-1.webp";
import student from "../images/study-student.svg";
import teacher from "../images/teacher-icon.svg";
import bedroom from "../images/bedroom.svg";

function BodySecond() {
  return (
    <div className="body-second">
      <div className="style-for"></div>
      <div className="container">
        <div className="left-panel">
          <div className="texts">
            <h1>Biz bilan dunyoning nufuzli Universitetlar</h1>
            <h2>Talabasiga aylaning</h2>
            <h1 id="logo-text"><span id="first">sulaymonov</span> <span id="second">academy</span></h1>
            <div className="buttons">
              <Link to={'education'}>
                <p id="first">
                  O'quv markazi <i class="bx bx-plus"></i>
                </p>
                <p id="second">Sulaymonov Academy</p>
              </Link>
              <Link to={'school'}>
                <p id="first">
                  Xususiy maktab <i class="bx bx-plus"></i>
                </p>
                <p id="second">Sulaymonov Academy</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="right-panel"></div>
        <div className="cards">
          <div className="card">
            <img src={student} alt="404" />
            <div className="text">
              <h1>Kuchli va Sharqona ta'lim tizimi</h1>
              <Link to={"/about"}>
                Batafsil <i class="bx bx-chevron-right"></i>
              </Link>
            </div>
          </div>
          <div className="card">
            <img src={teacher} alt="404" />
            <div className="text">
              <h1>Malakali o'qituvchilar tomonidan ta'lim</h1>
              <Link to={"/about"}>
                Batafsil <i class="bx bx-chevron-right"></i>
              </Link>
            </div>
          </div>
          <div className="card">
          <img src={bedroom} alt="404" />
            <div className="text">
              <h1>Qulay va shinam yotoqxonalar</h1>
              <Link to={"/about"}>
                Batafsil <i class="bx bx-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodySecond;
