import React from 'react'
import AboutSchool from '../components/AboutSchool'
import AboutUsVideo from '../components/AboutUsVideo'
import CardsSchoolAbout from '../components/CardsSchoolAbout'
import SchoolMain from '../components/SchoolMain'

function School() {
  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);
  return (
    <div className='school'>
      <div className="container">
        <SchoolMain />
        <AboutSchool /> 
        <CardsSchoolAbout />
        <AboutUsVideo />
      </div>
    </div>
  )
}

export default School