import React from "react";
import { Link } from "react-router-dom";
import student from "../images/student.png";

function SocialSciences() {
  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);
  return (
    <div className="courses-main">
      <div className="container">
        <div className="top-part">
          <div className="course-main">
            <h1>O'zingizga mos yo'nalishni tanlang </h1>
            <p>
              Biz bilan birga dunyodagi eng rivojlangan va talab yuqori bo'lgan
              sohalar mutaxasisi bo'ling. Buning uchun bizning kurslarimiz siz
              uchun juda ham ajoyib taklif
            </p>
          </div>
        </div>
        <div className="bottom-part">
          <div className="course-card">
            <Link to={"/inon"}>
              <div className="card" id="card-1">
                <div className="first-text">
                  <h1>Ingliz tili - Ona tili</h1>
                </div>
                <div className="second-text">
                  <p>
                    Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/taon"}>
              <div className="card" id="card-2">
                <div className="first-text">
                  <h1>Tarix - Ona tili </h1>
                </div>
                <div className="second-text">
                  <p>
                  Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/tain"}>
              <div className="card" id="card-3">
                <div className="first-text">
                  <h1>Tarix - Chet tili </h1>
                </div>
                <div className="second-text">
                  <p>
                  Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/huin"}>
              <div className="card" id="card-4">
                <div className="first-text">
                  <h1>Huquq - Chet tili</h1>
                </div>
                <div className="second-text">
                  <p>
                  Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/ruon"}>
              <div className="card" id="card-5">
                <div className="first-text">
                  <h1>Rus tili - Ona tili</h1>
                </div>
                <div className="second-text">
                  <p>
                  Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/tage"}>
              <div className="card" id="card-6">
                <div className="first-text">
                  <h1>Tarix - Geografiya</h1>
                </div>
                <div className="second-text">
                  <p>
                  Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                  </p>
                </div>
                <div className="third-text">
                  <p>
                    Batafsil <i class="bx bx-right-arrow-circle"></i>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="benefits">
          <div className="container">
            <div className="left-div">
              <div className="text">
                <h1>Imtiyozlar</h1>
                <p>
                Siz qaysi oliygohlarni tanlashingiz uchun batafsil tugmasi ustiga bosing
                </p>
                <button>
                  Batafsil <i className="bx bx-chevron-right"></i>
                </button>
              </div>
            </div>
            <div className="right-div">
              <img src={student} alt="404" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialSciences;
