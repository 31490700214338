import React from 'react'
import About from '../components/About'
import BodySecond from '../components/BodySecond'

function Main() {
  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);
  return (
    <main>
      <BodySecond />
      <About />
    </main>
  )
}

export default Main