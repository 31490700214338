import React from "react";
import result from "../images/results.png";
import promNight from "../videos/bitiruv.MP4";
import logo from "../images/logo-first.png";
import promNightImg from "../images/promNight.png";

function Results() {
  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);
  return (
    <div className="results">
      <div className="container">
        <div className="nav"></div>
        <div className="results-cards">
          <div className="top-panel">
            <h1>Bizning natijalar</h1>
          </div>
          <div className="bottom-panel">
            <div className="left-part">
              <img src={result} alt="404" />
            </div>
            <div className="right-part">
              <h1>Bizning so'nggi 5 yillik natijamiz</h1>
              <div className="cards">
                <div className="card" id="card-2">
                  <p>2018-2019 o'quv yilida natijamiz</p>
                  <h5>91%</h5>
                </div>
                <div className="card" id="card-3">
                  <p>2019-2020 o'quv yilida natijamiz</p>
                  <h5>92%</h5>
                </div>
                <div className="card" id="card-4">
                  <p>2020-2021 o'quv yilida natijamiz</p>
                  <h5>92%</h5>
                </div>
                <div className="card" id="card-5">
                  <p>2021-2022 o'quv yilida natijamiz</p>
                  <h5>93%</h5>
                </div>
                <div className="card" id="card-1">
                  <p>2022-2023 o'quv yilida natijamiz</p>
                  <h5>95%</h5>
                </div>
                {/* <div className="card" id="card-6"></div> */}
                {/* <div className="card" id="card-7"></div>
                <div className="card" id="card-8"></div>
                <div className="card" id="card-9"></div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="prom-night">
          <div className="left-panel">
            <video controls poster={promNightImg}>
              <source src={promNight} type="video/mp4"/>
            </video>
          </div>
          <div className="right-panel">
            <div className="cards">
              <h1>2023-yil bitiruv oqshomi</h1>
              <div className="card">
                <img src={logo} alt="404" />
                <div className="texts">
                  <p>
                    Sulaymonov Academy o'quv markazini 2023-yilda 400 dan ortiq
                    talaba bitirdi va ularni 98% oliy o'quv yurtlariga o'qishga
                    kirdilar
                  </p>

                  <button>Biz bilan bog'lanish</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
