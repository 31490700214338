import React from "react";
import student from "../images/student.png"

function Coursess() {
  return (
    <div className="courses-main">
      <div className="container">
        <div className="top-part">
          <div className="course-main">
            <h1>O'zingizga mos yo'nalishni tanlang </h1>
            <p>
              Biz bilan birga dunyodagi eng rivojlangan va talab yuqori bo'lgan
              sohalar mutaxasisi bo'ling. Buning uchun bizning kurslarimiz siz uchun juda ham ajoyib taklif
            </p>
          </div>
        </div>
        <div className="bottom-part">
          <div className="course-card">
            <div className="card" id="card-1">
              <div className="first-text">
                <h1>Matematika-Fizika</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-2">
              <div className="first-text">
                <h1>Matematika-Ingliz tili</h1>
              </div>
              <div className="second-text">
                <p>
                  Iqtisodiyot, Buxgalteriya, Aviatsiya, Biznes tahlili sohalari , Bank xodimi , Yuridik ishlar bo'limi xodimi va shunga o'xshash sohalar uchun asosiy fanlar
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-3">
              <div className="first-text">
                <h1>Huquq-Ona tili</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-4">
              <div className="first-text">
                <h1>Kimyo-Biologiya</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-5">
              <div className="first-text">
                <h1>Tarix-Geografiya</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-6">
              <div className="first-text">
                <h1>Fizika-Ingliz tili</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-7">
              <div className="first-text">
                <h1>Geografiya-Ona tili</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
            <div className="card" id="card-8">
              <div className="first-text">
                <h1>Ingliz tili-Rus tili</h1>
              </div>
              <div className="second-text">
                <p>
                  Transport muhandisligi , aviatsiya va temir yo'l muhandisligi
                  , O'qituchilik , Injinering , Dasturchilik va IT sohalari
                  mutaxasislari uchun
                </p>
              </div>
              <div className="third-text">
                <p>Kurs narxi: 1.200.000 so'm</p>
              </div>
            </div>
          </div>
        </div>
        <div className="benefits">
          <div className="container">
            <div className="left-div">
              <div className="text">
                <h1>Imtiyozlar</h1>
                <p>Agarda sizning hozirgi moliyaviy vaziyatingiz bizda o'qish imkoniyatini bera olmasa afsuslanmang ! Siz uchun maxsus imtiyozlarimiz mavjud</p>
                <button>Batafsil <i className='bx bx-chevron-right' ></i></button>
              </div>
            </div>
            <div className="right-div">
              <img src={student} alt="404" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coursess;
