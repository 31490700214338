import React from "react";
import { Link } from "react-router-dom";
import video from "../videos/students.MOV";

function Education() {
  setTimeout(() => {
    window.scrollTo(200, 0);
  }, 800);
  return (
    <div className="education">
      <div className="container">
        <div className="left-panel">
          <div className="texts-cards">
            <h1 id="ename">
              Sulaymonov <span>Academy</span>
            </h1>
            <div className="cards">
              <Link to={"/exactSciences"}>
                <div className="card">
                  <div className="left-part">
                    <h1>Aniq fanlar</h1>
                  </div>
                  <div className="right-part">
                    <i class="bx bxs-briefcase"></i>
                    <h1>Sulaymonov Academy</h1>
                  </div>
                </div>
              </Link>
              <Link to={"/socialSciences"}>
                <div className="card">
                  <div className="left-part">
                    <h1>Ijtimoiy fanlar</h1>
                  </div>
                  <div className="right-part">
                    <i class="bx bx-dollar"></i>
                    <h1>Sulaymonov Academy</h1>
                  </div>
                </div>
              </Link>
              <Link to={"/naturalSciences"}>
                <div className="card">
                  <div className="left-part">
                    <h1>Tabiiy fanlar</h1>
                  </div>
                  <div className="right-part">
                    <i class="bx bx-plus-medical"></i>
                    <h1>Sulaymonov Academy</h1>
                  </div>
                </div>
              </Link>
              <Link to={"/teachers"}>
                <div className="card">
                  <div className="left-part">
                    <h1>O'qituvchilarimiz jamoasi</h1>
                  </div>
                  <div className="right-part">
                  <i class='bx bxs-group'></i>
                    <h1>Sulaymonov Academy</h1>
                  </div>
                </div>
              </Link>
              <Link to={"/results"}>
                <div className="card">
                  <div className="left-part">
                    <h1>Natijalar</h1>
                  </div>
                  <div className="right-part">
                  <i class='bx bxs-group'></i>
                    <h1>Sulaymonov Academy</h1>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="right-panel">
          <div className="video">
            <video src={video} autoPlay muted frameborder="0"></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;
