import React from "react";
import student1 from "../images/oquvchi-1.jpg";
import student2 from "../images/oquvchi-2.jpg";
import student3 from "../images/oquvchi-3.jpg";

function AboutSchool() {
  let counter = 1;

  return (
    <div className="about-school">
      <div className="container">
        <div className="left-div">
          <div className="top">
            <h1>
              Nega aynan <span id="text1">Sulaymonov Academy ?</span>
            </h1>
          </div>
          <div className="body">
            <div className="texts-body" id="text-body-1">
              <h1>
                Bizda 5-sinfdan 11-sinfgacha sinflar mavjud va ko'plab
                ko'nikmalar malakali o'qituvchilar tomonidan berib boriladi
              </h1>
              <p>
                Bizning vazifamiz - o'quvchilarda jamiyat rivojiga o'z
                hissalarini qo'shishlari uchun kerak bo'ladigan bilim va axloqni
                shakillantirish
              </p>
            </div>
            <div className="texts-body" id="text-body-2">
              <h1>
                Bolalar uchun maxsus transport vositasi tashkil etilgan va
                farzandinzgiz sog' salomat borib kelishi taminlanadi
              </h1>
              <p>
                O'quvchilar darslarga o'z vaqtida kelishadi va uylariga o'z
                vaqtida borishadi
              </p>
            </div>
            <div className="texts-body" id="text-body-3">
              <h1>
                O'quvchilar uchun yotoqxona mavjud bo'lib, ularning uydan olisda
                bo'lsa ham ta'lim olishlari uchun qulaylik ta'minlangan.
              </h1>
              <p>
                Yotoqxonada kuchli tartib intizomga rioya qilinadi va 24 soat davomida nazoratchi xodimlar tomonidan kuzatilinadi
              </p>
            </div>
          </div>
          <div className="bottom">
            <div className="arrow">
              <i
                class="bx bx-left-arrow-alt"
                onClick={() => {
                  if (counter === 1) {
                    console.log("first: " + counter);
                    document.querySelector(`#img-student-${counter}`).style =
                      "opacity: 0;";
                    document.querySelector(`#text-body-${counter}`).style =
                      "opacity: 0;";
                    counter += 2;
                    console.log("second: " + counter);
                    document.querySelector(`#img-student-${counter}`).style =
                      "opacity: 1;";
                    document.querySelector(`#text-body-${counter}`).style =
                      "opacity: 1;";
                  } else if (counter === 2) {
                    console.log("first: " + counter);
                    document.querySelector(`#img-student-${counter}`).style =
                      "opacity: 0;";
                    document.querySelector(`#text-body-${counter}`).style =
                      "opacity: 0;";
                    counter -= 1;
                    console.log("second: " + counter);
                    document.querySelector(`#img-student-${counter}`).style =
                      "opacity: 1;";
                    document.querySelector(`#text-body-${counter}`).style =
                      "opacity: 1;";
                  } else if (counter === 3) {
                    console.log("first: " + counter);
                    document.querySelector(`#img-student-${counter}`).style =
                      "opacity: 0;";
                    document.querySelector(`#text-body-${counter}`).style =
                      "opacity: 0;";
                    counter -= 1;
                    console.log("second: " + counter);
                    document.querySelector(`#img-student-${counter}`).style =
                      "opacity: 1;";
                    document.querySelector(`#text-body-${counter}`).style =
                      "opacity: 1;";
                  }
                }}
              ></i>
            </div>
            <div
              className="arrow"
              onClick={() => {
                if (counter === 1) {
                  console.log("first: " + counter);
                  document.querySelector(`#img-student-${counter}`).style =
                    "opacity: 0;";
                  document.querySelector(`#text-body-${counter}`).style =
                    "opacity: 0;";
                  counter += 1;
                  console.log("second: " + counter);
                  document.querySelector(`#img-student-${counter}`).style =
                    "opacity: 1;";
                  document.querySelector(`#text-body-${counter}`).style =
                    "opacity: 1;";
                } else if (counter === 2) {
                  console.log("first: " + counter);
                  document.querySelector(`#img-student-${counter}`).style =
                    "opacity: 0;";
                  document.querySelector(`#text-body-${counter}`).style =
                    "opacity: 0;";
                  counter += 1;
                  console.log("second: " + counter);
                  document.querySelector(`#img-student-${counter}`).style =
                    "opacity: 1;";
                  document.querySelector(`#text-body-${counter}`).style =
                    "opacity: 1;";
                } else if (counter === 3) {
                  console.log("first: " + counter);
                  document.querySelector(`#img-student-${counter}`).style =
                    "opacity: 0;";
                  document.querySelector(`#text-body-${counter}`).style =
                    "opacity: 0;";
                  counter -= 2;
                  console.log("second: " + counter);
                  document.querySelector(`#img-student-${counter}`).style =
                    "opacity: 1;";
                  document.querySelector(`#text-body-${counter}`).style =
                    "opacity: 1;";
                }
              }}
            >
              <i class="bx bx-right-arrow-alt"></i>
            </div>
          </div>
        </div>
        <div className="right-div">
          <div className="image" id="img-student-1">
            <img src={student1} alt="404" />
          </div>
          <div className="image" id="img-student-2">
            <img src={student2} alt="404" />
          </div>
          <div className="image" id="img-student-3">
            <img src={student3} alt="404" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSchool;
