import React from "react";
import group from "../images/group-icon.svg";
import group2 from "../images/group.svg";
import map from "../images/location-on-map.svg";
import teacher from "../images/teacher.svg";
import book from "../images/open-book.svg";
import collage from "../images/collage.svg";
import gender from "../images/gender.svg";
import sport from "../images/sport.svg";

function CardsSchoolAbout() {
  return (
    <div className="cards-about-school">
      <div className="container">
        <div className="cards-texts">
          <h1>
            Sulaymonov Academy maktabi <span>raqamlarda</span>{" "}
          </h1>
          <div className="cards">
            <div className="card" id="card-1">
              <img src={group} alt="404" />
              <div className="texts">
                <h1>367</h1>
                <p>Jami ta'lim oluvchilar soni</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Voluptatum incidunt eius iure at officia? Incidunt
                    temporibus deleniti accusam
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-2">
              <img src={teacher} alt="404" />
              <div className="texts">
                <h1>35</h1>
                <p>O'qituvchilarimizning soni</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    Bizda malakali o'qituvchilar tomonidan darslar olib boriladi
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-3">
              <img src={map} alt="404" />
              <div className="texts">
                <h1>73</h1>
                <p>Viloyatlardan kelib o'qiydigan o'quvchilar soni</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    Bizda har qaysi viloyatlardan kelib o'quvchilar ta'lim olishadi
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-4">
              <img src={group2} alt="404" />
              <div className="texts">
                <h1>52</h1>
                <p>Kontrakt asosida qabul qilinganlar</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    Kontrakt asosida qabul qilinganlar soni
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-5">
              <img src={book} alt="404" />
              <div className="texts">
                <h1>58</h1>
                <p>Grand asosida qabul qilinganlar</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    Grand asosida qabul qilinganlar soni
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-6">
              <img src={collage} alt="404" />
              <div className="texts">
                <h1>110</h1>
                <p>Oliy o'quv yurtiga qabul qilinganlar soni</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    Oliy hamda akademik litseyga kirgan o'quvchilarimiz soni 
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-7">
              <img src={gender} alt="404" />
              <div className="texts">
                <h1>63</h1>
                <p>9-sinfdan 11-sinfgacha bo'lgan o'quvchilar</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    O'quvchilar imtihon asosida qabul qilinadi
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
            <div className="card" id="card-8">
              <img src={sport} alt="404" />
              <div className="texts">
                <h1>21</h1>
                <p>Bitiruvchilar soni</p>
              </div>
              <div className="hide-hover-texts">
                <div className="text">
                  <p id="hide-text-hover">
                    2023-yilda bizning maktab bitiruvchilari soni
                  </p>
                  <a href="tel:+998978002809">Batafsil</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardsSchoolAbout;
